// Star trial Page
.start-trial-card {
  height: 700px;
  width: 390px;
  background: url('/img/side-menu-bg.jpg') no-repeat;
  background-position: center;
  background-size: cover;
  padding: 20px 0 1.5em 5px;
  border-radius: 10px;
}

.start-trial-card-title {
  text-align: center;

  h3 {
    font-family: Eastman, sans-serif;
    font-weight: 300;
  }
}

.start-trial-card-content {
  margin-top: 0;
  text-align: center;
  padding: 15px;

  p {
    opacity: 0.7;
  }
}

.start-trial-card-footer-center-button {
  margin-left: 0px;
}

.start-trial-card-back {
  z-index: 10;

  .start-trial-back-btn {
    cursor: pointer;
  }

  .start-trial-progress {
    line-height: 21px;
  }
}

.start-trial-card-progress {
  z-index: 10;
  position: relative;
  right: 10px;
}

@keyframes startTrialfadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.start-trial-card-shown {
  animation: startTrialfadeIn 0.5s;
}

@keyframes startTrialfadeOut {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

.start-trial-card-hidden {
  animation: startTrialfadeOut 0.5s;
}