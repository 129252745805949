.dropshadow-1 {
  box-shadow: 3px 3px 4px -3px rgba(0,0,0,0.35);
  -webkit-box-shadow: 3px 3px 4px -3px rgba(0,0,0,0.35);
  -moz-box-shadow: 3px 3px 4px -3px rgba(0,0,0,0.35);
}

.muted {
  opacity: 0.7;
}

.text-bold {
  font-weight: 700;
}

.text-small {
  font-size: max(0.8em, 8px);
}

.vh-50 {
  height: 50vh;
}

.vh-75 {
  height: 75vh;
}
