$table-hover-bg: #f8f9fa;

@import "~bootstrap/scss/bootstrap";
@import "./defaults.scss";
@import "./start-trial.scss";
@import "./bootstrap-changes.scss";

@font-face {
  font-family: 'Eastman-Regular';
  src: url('/fonts/Eastman-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Eastman-Bold';
  src: url('/fonts/Eastman-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

:root {
  --clr-border-primary: #DEE2E6;
  --border-radius-1: 5px;
  --padding-1: 0.4em;
  --padding-2: 0.6em;
  --padding-3: 0.8em;
  --padding-4: 1em;
}

html,
body {
  padding: 0;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
    Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
  background-color: rgb(250, 250, 250);
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}

$brand_primary: rgb(37, 82, 122);
$brand_secondary: rgb(93, 173, 189);

$primary: $brand_primary;

$text_primary: #FCFDED;

.bg-brand-primary {
  background-color: $brand_primary;
}

.text-brand-primary {
  color: $text_primary;
}

.text-brand-secondary {
  color: $brand_secondary;
}

.show-content-on-hover .show-on-hover * {
  visibility: hidden;
}

.show-content-on-hover:hover .show-on-hover * {
  visibility: visible;
}

.page-title {
  display: inline-block;
}

code {
  color: var(--blue);
}

.container {
  .row-card:last-of-type {
    margin-bottom: 100px !important;
  }
}

.note-paragraph-container {
  p {
    margin-bottom: 0;
    margin-top: 0.5rem;
  }

  p:first-child {
    margin-top: 0;
  }
}

.mw-65ch {
  max-width: 65ch;
}

.helpbar-bg-image {
  background: url('/img/side-menu-bg.jpg') no-repeat;
  background-position: center;
  background-size: cover;
}
